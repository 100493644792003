@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body[data-scroll-locked] {
  margin-right: 0!important;
}

.chakra-avatar__initials {
  font-weight: normal;
  font-size: 12px !important;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.optimize-contrast {
  image-rendering: optimize-contrast;
  image-rendering: -webkit-optimize-contrast;
}

.picker-dialog {
  outline: 0;
  z-index: 10001 !important;
}

.picker-dialog-bg {
  z-index: 10000 !important;
}

/* width */
.chakra-ui-dark ::-webkit-scrollbar,
.chakra-ui-light ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.chakra-ui-dark ::-webkit-scrollbar-track {
  background: #171923;
  border-radius: 10px;
}
.chakra-ui-light ::-webkit-scrollbar-track {
  background: #e2e8f0;
  border-radius: 10px;
}

/* Handle */
.chakra-ui-dark ::-webkit-scrollbar-thumb {
  background: #1e2431;
  border-radius: 10px;
}
.chakra-ui-light ::-webkit-scrollbar-thumb {
  background: #c1c4c7;
  border-radius: 10px;
}
#react-tiny-popover-container {
  z-index: 1 !important;
}

.highlight-bg-transparent span {
  background-color: transparent !important;
}

.cm-tooltip-hover {
  position: fixed !important;
  top: 10rem !important;
  right: 3rem !important;
  left: unset !important;
  max-width: 23rem !important;
  display: inline-block;
  border-radius: 10px;
  padding: 1rem;
  font-size: "10px!important";
  line-height: "1.5rem!important";
  background-color: #e2e8f0;
  color: #2d3748;
}
.chakra-ui-dark .cm-tooltip-hover {
  background-color: #1a202c;
  color: #cbd5e0;
}

@media screen and (max-width: 30em) {
  .picker {
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
  }
}
.chakra-switch span:focus,
.chakra-select:focus,
.ch-multi-select > div[data-focus] {
  box-shadow: none !important;
  border-color: inherit !important;
}
.chakra-switch__track[aria-checked="true"],
.chakra-switch__track[data-checked] {
  box-shadow: none !important;
  border-color: inherit !important;
  background-color: #4299e1 !important;
}
.chakra-switch__track[data-focus] {
  box-shadow: none !important;
  border-color: inherit !important;
}

/* calendar component styles */
.ch-collection-calendar .rbc-time-view .rbc-time-gutter {
  font-size: 14px;
  padding: 0 4px;
}
.ch-collection-calendar .rbc-day-slot .rbc-event-content {
  line-height: 1.2;
  padding-top: 3px;
}

.ch-calendar-dark * {
  border-color: var(--chakra-colors-gray-700) !important;
}

.ch-calendar-dark .rbc-today {
  background-color: var(--chakra-colors-gray-800) !important;
}

.ch-calendar-dark .rbc-toolbar button:active,
.ch-calendar-dark .rbc-toolbar button:focus,
.ch-calendar-dark .rbc-toolbar button.rbc-active,
.ch-calendar-dark .rbc-toolbar button:hover {
  color: var(--chakra-colors-gray-300) !important;
  border-color: var(--chakra-colors-gray-600) !important;
  background-color: var(--chakra-colors-gray-800) !important;
}

.ch-calendar-dark .rbc-btn-group button {
  color: var(--chakra-colors-gray-300);
  border-color: var(--chakra-colors-gray-600);
  background-color: var(--chakra-colors-gray-700);
}

.carousel .slide {
  background-color: unset !important;
}

.carousel .control-dots .dot {
  width: 1rem !important;
  height: 1rem !important;
  background-color: gray !important;
}

.carousel .control-dots .dot:focus {
  outline: none !important;
}

.carousel .control-dots .dot.selected {
  background-color: orange !important;
}

.carousel .control-prev.control-arrow {
  opacity: 1 !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 12px solid gray !important;
}
.carousel .control-prev.control-arrow:hover:before {
  border-right: 12px solid #21c7a8 !important;
}

.carousel .control-next.control-arrow {
  opacity: 1 !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 12px solid gray !important;
}
.carousel .control-next.control-arrow:hover:before {
  border-left: 12px solid #21c7a8 !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: unset !important;
}

.highlighed-text::after {
  position: absolute;
  width: 110%;
  background-color: #fac832;
  content: "";
  z-index: -1;
  bottom: 0.4rem;
  left: -0.05rem;
  height: 0.7rem;
  width: 101%;
}

.highlighted-text-dark::after {
  background-color: #4f4f4f !important;
  content: "";
}

@media screen and (min-width: 23.75em) {
  .highlighed-text::after {
    bottom: 0.5rem;
    left: -0.1rem;
    height: 1rem;
    width: 102%;
  }
}

@media screen and (min-width: 62em) {
  .highlighed-text::after {
    bottom: 0.8rem;
    left: -0.4rem;
    height: 1.4rem;
    width: 110%;
  }
}

.chakra-textarea:focus {
  box-shadow: none !important;
  border-color: #cbd5e0 !important;
}

.chakra-text ul {
  padding-left: 1.2rem;
}

.chakra-text ol {
  padding-left: 1rem;
}

.onboarding-underline::after {
  position: absolute;
  bottom: 0.75rem;
  left: 0;
  height: 1rem;
  width: 100%;
  background-color: #fac832;
  content: "";
  z-index: -1;
}

@media screen and (max-width: 30em) {
  .onboarding-underline::after {
    height: 0.6rem;
    bottom: 0.55rem;
  }
}

@media screen and (max-width: 48em) {
  .onboarding-underline::after {
    height: 0.7rem;
    bottom: 0.6rem;
  }
}

.uppy-DashboardContent-removeAll {
  color: tomato !important;
  font-weight: 600 !important;
}
.uppy-Dashboard-inner {
  border-radius: 0 !important;
  z-index: 10000 !important;
}

[data-uppy-theme="dark"] .uppy-Dashboard-inner,
[data-uppy-theme="dark"] .uppy-DashboardContent-bar {
  background-color: #2e3542 !important;
}

.note-editor.hide-placeholder .public-DraftEditorPlaceholder-root {
  display: none;
}
.note-editor .DraftEditor-editorContainer ul,
.note-editor.rdw-editor-main ol {
  margin: 0;
}

.rdw-option-wrapper {
  border: none !important;
}
.chakra-ui-dark .rdw-option-wrapper {
  background: #919191 !important;
}
.chakra-ui-dark .rdw-option-wrapper:hover {
  background: #ebebeb !important;
  box-shadow: none !important;
}
.chakra-ui-dark .rdw-option-active {
  background: #ebebeb !important;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.react-datepicker__tab-loop {
  margin: 0 !important;
}

button.react-datepicker__close-icon::after {
  background-color: #4299e1;
}

.filter-container button.react-datepicker__navigation--previous,
.filter-container button.react-datepicker__navigation--next {
  margin-top: 0.4rem;
}

.chakra-ui-dark .react-datepicker__current-month,
.chakra-ui-dark .react-datepicker__day-name,
.chakra-ui-dark .react-datepicker__day {
  color: white !important;
}
.chakra-ui-dark .react-datepicker__month-container {
  background-color: #1a202c;
}

.react-datepicker__header {
  border-color: #cbd5e0 !important;
  background-color: #fbfafd !important;
}
.react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #fbfafd;
}
.chakra-ui-dark .react-datepicker__header {
  border-color: #4a5568 !important;
  background-color: #1a202c !important;
}

.chakra-ui-dark .react-datepicker__day--selected,
.chakra-ui-dark .react-datepicker__day--keyboard-selected,
.chakra-ui-dark .react-datepicker__time-list-item--selected {
  background-color: #4a5568 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-list-item--selected {
  background-color: #4299e1 !important;
}
.reactgrid .rg-pane-top {
  z-index: 1 !important;
}

.chakra-ui-dark .react-datepicker__day:hover {
  background-color: #4a5568 !important;
}
.chakra-ui-dark .rg-pane-top {
  background-color: #1a202c !important;
}
.chakra-ui-dark .rg-cell {
  background-color: "transparent" !important;
}

.ch-dateEditSaving {
  background-color: transparent !important;
  opacity: 0.4 !important;
  cursor: not-allowed !important;
}

.date-edit,
.date-edit-dark {
  font-size: 0.875rem;
  padding-left: 1rem;
  height: 1.5rem !important;
  outline: 2px solid transparent;
  width: 100%;
  text-align: left;
}

.date-edit-dark {
  background-color: #2d3748 !important;
  color: #cbd5e0;
}
.uppy-size--md .uppy-Dashboard-AddFiles-title {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}
.uppy-size--md .uppy-Dashboard-AddFiles-title button {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}
.uppy-size--md .uppy-Dashboard-Item-action--remove {
  right: -6px;
  color: #009ad8 !important;
}
.uppy-size--md .uppy-Dashboard-note {
  font-size: 0.875rem !important;
}
.ch-note ul {
  padding-left: 1.1rem !important;
}

.ch-note ol {
  padding-left: 1rem !important;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.child-panel {
  box-shadow: 0px 0px 85px #232931 !important;
}

.ch-menu-list,
.chakra-menu__menuitem {
  transition: none !important;
  transform: none !important;
  transform-origin: unset !important;
  opacity: 1 !important;
}

.chakra-menu__group,
.chakra-menu__menuitem:hover,
.chakra-menu__menuitem:active,
.chakra-menu__menuitem:focus {
  background-color: transparent !important;
}

.ch-menu-item-dark {
  background-color: #2d3748 !important;
  color: #fff !important;
}

.ch-menu-dark {
  background-color: #2d3748 !important;
  border-radius: 0.5rem !important;
  border: 1px solid #2d3748 !important;
  padding: 0 !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.ch-menu-light {
  background-color: #fff !important;
  border-radius: 0.5rem !important;
  border: 1px solid #cbd5e0 !important;
  padding: 0 !important;
  background-color: #fff !important;
}
